var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "item-requiring-attention-modal",
      attrs: {
        id: "item-requiring-attention-modal",
        title: _vm.setTitle,
        size: "lg",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
        "hide-footer": "",
      },
    },
    [
      _c("item-requiring-attention", {
        attrs: { item: _vm.item, services: _vm.services },
        on: { completed: _vm.completed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }