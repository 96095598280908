<template>
  <b-modal
    id="item-requiring-attention-modal"
    ref="item-requiring-attention-modal"
    :title="setTitle"
    size="lg"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
  >
    <item-requiring-attention
      :item="item"
      :services="services"
      @completed="completed"
    />
  </b-modal>
</template>

<script>
import ItemRequiringAttention from '@/components/ItemRequiringAttention'

export default {
  name: 'ItemRequiringAttentionModal',
  components: {
    ItemRequiringAttention,
  },
  data() {
    return {
      loading: true,
      item: null,
      services: false,
    }
  },
  computed: {
    setTitle() {
      return this.item?.company?.name || ''
    },
  },
  methods: {
    setItem(item) {
      this.item = item
    },
    setServices(services) {
      this.services = services
    },
    completed() {
      this.$emit('completed', this.item)
    },
    open() {
      this.$refs['item-requiring-attention-modal'].show()
    },
    close() {
      this.$nextTick(() => {
        this.$refs['item-requiring-attention-modal'].hide()
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
